import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid/Grid';
import { useState } from 'react';
import ScoreButton from './Components/ScoreButton';
import { scoresAndGifs } from './Data/scoresAndGifs';
import { ScoreAndGif } from './Models/ScoreAndGif';

export default function App() {
  const [score, setScore] = useState<string | null>(null)
  return (
    <Container maxWidth="sm">
      <div style={{height: '5vh'}}>
      <Typography variant="h5" style={{ textAlign: "center" }}>Planning Poker</Typography>

      </div>
      {
        score
          ?
          (
            <Grid onClick={() => setScore(null)} container>
              <Grid xs={12} item>
                <div style={{ width: '100%',position: 'relative', maxHeight: '45vh', minHeight: '45vh' }}>
                  <img style={{ width: '100%', maxHeight: '45vh', display: 'block', margin: 'auto', position:'absolute', top: 0, bottom: 0, left: 0, right: 0 }} src={scoresAndGifs.find(x => x.score === score)?.gifLink ?? ""} />
                </div>
              </Grid>
              <Grid xs={12} item style={{ paddingTop: '5vh' }}>
                <div style={{ margin: 'auto', border: '3px solid #1844A0', borderRadius: '50%', height: '30vh', width: '30vh' }}>
                  <Typography variant="h1" style={{ textAlign: "center", lineHeight: '30vh' }}>{score}</Typography>
                </div>
              </Grid>
            </Grid>
          )
          :
          (
            <Grid container spacing={5} style={{bottom: 20, left:0, position: 'absolute'}}>
              {scoresAndGifs.map((x: ScoreAndGif) => (
                <Grid xs={6} item>
                  <ScoreButton score={x.score} setScore={setScore} />
                </Grid>
              ))}
            </Grid>
          )
      }

    </Container>
  );
}


