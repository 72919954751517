import * as React from 'react';
import { Button } from '@mui/material';

type ScoreButtonProps = {
    score: string
    setScore: any
}

export default function ScoreButton({score,setScore}: ScoreButtonProps): JSX.Element {
  return (
    <Button size="large" style={{margin: 'auto', display: 'block', width: '70%'}} variant="outlined" onClick={() => setScore(score)}>{score}</Button>
  );
}


