import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';

import telematicsPosterDesktop from "./Data/telematics-desktop-thumbnail.webp";
import telematicsVideoDesktop from "./Data/telematics-desktop-cropped-hd.webm";
import "@fontsource/montserrat/900.css";
import "@fontsource/poppins";


const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const isSmallerThanDesktop = theme.breakpoints.down('lg');

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <video playsInline autoPlay muted loop poster={telematicsPosterDesktop} style={{
      height: 'auto',
      width: isSmallerThanDesktop ? '100vw' : '60vw',
      position: "fixed",
      bottom: "0",
      transform: "rotateX(180deg)",
      zIndex: -1
    }}>
      <source src={telematicsVideoDesktop} type="video/webm" />
    </video>
    <div style={{
      height: '100vh',
      width: isSmallerThanDesktop ? '93vw' : '95vw',
      position: "fixed",
      top: 0,
      left: isSmallerThanDesktop ? "7vw" : "5vw",
      backgroundColor: 'white',
      zIndex: -1
    }}>
    </div>
    <App />
  </ThemeProvider>,
);
