import { createTheme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

const primary = "#1844A0";
const secondary = "#C41237";
const white = "#ffffff";
const error = red[500];
const contrast = "#212121";

const primaryText = grey[900];
// A custom theme for this app
let theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h2: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h3: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h4: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h5: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h6: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    subtitle1: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    subtitle2: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: primary,
      contrastText: contrast,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
    background: {},
    text: {
      primary: primaryText,
      secondary: white
    },
  },
});

// // A custom theme for this app
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//   },
// });

export default theme;
