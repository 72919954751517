import { ScoreAndGif } from "../Models/ScoreAndGif";

const scoresAndGifs: ScoreAndGif[] = [
    {
        score: "0.5",
        gifLink: "https://media1.giphy.com/media/mXiBtLZk4ibkc/giphy.gif?cid=ecf05e47005hhf6kr12j6gvp5ae4e89sx2w75mp4dr0j7z3e&rid=giphy.gif&ct=g"
    },
    {
        score: "1",
        gifLink: "https://c.tenor.com/T-NammTFOt8AAAAC/dory-littleguy.gif"
    },
    {
        score: "2",
        gifLink: "https://c.tenor.com/dr760Xwe-xMAAAAC/spongebob-done.gif"
    },
    {
        score: "3",
        gifLink: "https://c.tenor.com/lEUxrlADXmQAAAAC/patrick-the-starfish-patrick.gif"
    },
    {
        score: "5",
        gifLink: "https://c.tenor.com/LVuvFvTIArcAAAAC/good-morning.gif"
    },
    {
        score: "8",
        gifLink: "https://c.tenor.com/c0j8qvkYhY0AAAAd/dracula-eight.gif"
    },
    {
        score: "13",
        gifLink: "https://media3.giphy.com/media/PMqhzKfuiMLQc/giphy.gif?cid=ecf05e47br3453kh3aclkl963kooobqtwq3ypcg0ewi4exir&rid=giphy.gif&ct=g"
    },
    {
        score: "20",
        gifLink: "https://c.tenor.com/XbNgMh6Cd1QAAAAC/everythings-fine-im-fine.gif"
    },
    {
        score: "40",
        gifLink: "https://c.tenor.com/PqgTNvSN8wIAAAAC/kermit-worried.gif"
    },
    {
        score: "100",
        gifLink: "https://c.tenor.com/vMvR0gEolCEAAAAC/nothing-to-see-here-explosion.gif"
    },
    {
        score: "?",
        gifLink: "https://c.tenor.com/9F_duOWDcrMAAAAC/what-minions.gif"
    },
    {
        score: "∞",
        gifLink: "https://c.tenor.com/-rnEYzty4DoAAAAC/theoffice-no.gif"
    }
]

export { scoresAndGifs }
